import React, { useEffect, useState } from "react"
import ReactSelect from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { useLocation } from "@reach/router"
import "./PaginationCus.scss"
import $ from "jquery"

const PaginationCus = ({
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
  currentItems,
  selectOptions,
  allCategoryOption
}) => {
  const { isMobile } = useDeviceMedia()

  const pageNumbers = []
  const pageNumberOptions = []
  const location = useLocation()

  // Generate page numbers
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
    pageNumberOptions.push({ value: i, label: i })
  }
  const [pageNumbersToShow, setPageNumbersToShow] = useState([1]);
  const [totalPageNumbers, setTotalPageNumbers] = useState(pageNumbers.length);

  // Pagination condition added
  useEffect(() => {
    if (pageNumbers.length > 5) {
      const leftIndex = currentPage - Math.ceil(5/2);
      const rightIndex = currentPage + Math.floor(5/2);
      if (leftIndex < 1) {
        setPageNumbersToShow(pageNumbers.slice(0, 5))
      } else if (rightIndex > pageNumbers.length) {
        setPageNumbersToShow(pageNumbers.slice(pageNumbers.length - 5, pageNumbers.length))
      } else if (leftIndex >= 1 || rightIndex <= 5) {
        setPageNumbersToShow(pageNumbers.slice(leftIndex, rightIndex))
      }
      setTotalPageNumbers(pageNumbers.length);
    } else if(pageNumbers.length <= 5){
      setPageNumbersToShow(pageNumbers);
      setTotalPageNumbers(pageNumbers.length);
    }
  }, [currentPage,currentItems,totalPageNumbers,selectOptions,allCategoryOption])

  const handlePageChange = value => {
    // window.scrollTo({ top: 0, behavior: "smooth" })
    $("html, body").animate({ scrollTop: isMobile ? 250 : 350 }, 800);
    if(location?.pathname == "/meet-the-team/" || location?.pathname == "/meet-the-team"){
      if (typeof value === "number")  {
        sessionStorage.setItem('setInitialValue', value);
      } else {
        sessionStorage.setItem('setInitialValue', 1);
      }
    }
    
    // handle change for dropdown select  
    if (typeof value === "object") setCurrentPage(value.value)
    // handle change for number based page change
    else if (typeof value === "number") setCurrentPage(value)
    else
      switch (value) {
        case "back":
          setCurrentPage(page => --page)
          break
        case "next":
          setCurrentPage(page => ++page)
          break
        default:
          break
      }
  }

  if(totalPageNumbers < 2) return null

  return (
    <nav className="pagination-wrapper">
      <div className="pagination-container">
        <button
          onClick={() => handlePageChange("back")}
          className={`button button-secondary pagination-button button-back ${
            currentPage <= 1 ? "button-disabled" : ""
          }`}
          disabled={currentPage <= 1}
        >
          <i className="icon icon-arrow-left"></i>
        </button>

        <div className="pagination-numbers-wrap">
            {pageNumbersToShow?.map(number => (
              <button
                onClick={() => handlePageChange(number)}
                className={`button button-secondary pagination-button ${
                  parseInt(currentPage) === number ? "button-active" : ""
                }`}
              >
                {number}
              </button>
            ))}
          </div>

       
        <button
          onClick={() => handlePageChange("next")}
          className={`button button-secondary pagination-button button-next ${
            currentPage >= totalPageNumbers ? "button-disabled" : ""
          }`}
          disabled={currentPage >= totalPageNumbers}
        >          
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>
    </nav>
  )
}

export default PaginationCus