import React from 'react';
import Header from '../../components/Header/Header';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import FilterBlock from '../../components/SearchFilters/FilterBottom';
import Propertysale from '../../components/PropertySale/PropertySale'
import AboutResult from "../../components/AboutResult/AboutResult"
import  Paginations  from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';


import "./SearchResult.scss"
const SearchResults = () => {
	return (
		<>
			<Header additionalClass="white header-white"/>
			{/* <SearchFilters />
			<FilterBlock/>
			<Propertysale/>
			<AboutResult/> */}
			<Paginations/>
			<Footer/>
		</>
	)
}

export default SearchResults