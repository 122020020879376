import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import propertyimg from "../../images/property-img.png";
import Sale02 from "../../images/search/sale02.png";
import Sale03 from "../../images/search/sales03.png";
import propertyTab01 from "../../images/tab-property01.png";
import propertyTab02 from "../../images/tab-property02.png";
import Desk01 from "../../images/search/desk01.png";
import Desk02 from "../../images/search/desk-1402.png";
import Desk03 from "../../images/search/desk03.png";
import Desk04 from "../../images/search/desk04.png";
import Desk05 from "../../images/search/desk05.png";
import Desk06 from "../../images/search/desk06.png";

import Desk001 from "../../images/search/desk001.png";
import Desk002 from "../../images/search/desk002.png";
import Desk003 from "../../images/search/desk003.png";
import Desk004 from "../../images/search/desk004.png";
import Desk005 from "../../images/search/desk005.png";
import Desk006 from "../../images/search/desk006.png";

import Map from "../../images/map.png";
import { useWindowSize } from "../../hooks/window-size";
import PropertyCard from "../PropertyCard/PropertyCard";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "./PropertySale.scss";
const propertyData = [
  {
    propertyImg: propertyimg,
    propertyTab: propertyTab01,
    propertyDesk: Desk01,
    Desk: Desk001,
    propertyTitle: "AED 5,550,000",
    propertyInfo: "Green Community Motor City, Motor City",
    propertyRoom: "4",
    propertyBathroom: "2",
    propertyReception: "1,019 sqft",
  },
  {
    propertyImg: Sale02,
    propertyTab: propertyTab02,
    propertyDesk: Desk02,
    Desk: Desk002,
    propertyTitle: "AED 670,000",
    propertyInfo: "New Dubai Gate 2, Jumeirah Lake Towers",
    propertyRoom: "4",
    propertyBathroom: "2",
    propertyReception: "1,203 sqft",
  },
  {
    propertyImg: Sale03,
    propertyTab: propertyTab01,
    propertyDesk: Desk03,
    Desk: Desk003,
    propertyTitle: "AED 1,100,000",
    propertyInfo: "Fairways, The Views",
    propertyRoom: "3",
    propertyBathroom: "2",
    propertyReception: "1,152 sqft",
  },
  {
    propertyImg: propertyimg,
    propertyTab: propertyTab02,
    propertyDesk: Desk04,
    Desk: Desk004,
    propertyTitle: "AED 5,550,000",
    propertyInfo: "Green Community Motor City, Motor City",
    propertyRoom: "4",
    propertyBathroom: "2",
    propertyReception: "1,019 sqft",
  },
  {
    propertyImg: propertyimg,
    propertyTab: propertyTab02,
    propertyDesk: Desk05,
    Desk: Desk005,

    propertyTitle: "AED 670,000",
    propertyInfo: "New Dubai Gate 2, Jumeirah Lake Towers",
    propertyRoom: "4",
    propertyBathroom: "2",
    propertyReception: "1,203 sqft",
  },
  {
    propertyImg: propertyimg,
    propertyTab: propertyTab02,
    propertyDesk: Desk06,
    Desk: Desk006,

    propertyTitle: "AED 1,100,000",
    propertyInfo: "Fairways, The Views",
    propertyRoom: "3",
    propertyBathroom: "2",
    propertyReception: "1,152 sqft",
  },
];

const PropertyFeatures = (props) => {
  const [windowWidth] = useWindowSize();
  const [propertyList, setBoxContent] = useState([]);

  const searchForOptions = ["Newest First", "Newest"];

  const [searchFor, setSearchFor] = useState("Newest First");
  const searchTypeDropdownHandler = (e) => {
    setSearchFor(e);
  };
  const [icon,SetIcon] = useState(false)
  const showIcon = ()=>{
    SetIcon(!icon)
  }

  useEffect(() => {
    if (windowWidth < 768) {
      return setBoxContent(propertyData.slice(0, 2));
    }
    if (windowWidth < 1200 && windowWidth > 767) {
      return setBoxContent(propertyData.slice(0, 4));
    }
    if (windowWidth > 1200) {
      return setBoxContent(propertyData);
    }
  }, [windowWidth]);

  return (
    <React.Fragment>
      <section className="property-sale">
        <Container>
          <Row>
            <Col md={12}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1 className="heading">Property for sale in Dubai</h1>
                <div className="info-wrap">
                  <div className="content-wrapper">
                    <div className="content-wrap">
                      <span className="number">148 </span>
                      <span className="sm-title d-md-none">properties</span>
                      <span className="sm-title d-none d-md-block">
                        properties found
                      </span>
                    </div>
                    <div className="dropdowns-wrap">
                      <Dropdown
                        onSelect={searchTypeDropdownHandler}
                        className="select-option" >
                        <Dropdown.Toggle id="dropdown-basic">
                          {searchFor}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {searchForOptions.map((option, index) => (
                            <Dropdown.Item key={index} eventKey={option}>
                              {option}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <Link href="#" className="icon-wrap">
                      <i onClick={showIcon}  className={icon ? "icon-fill" : "heart-light"}></i>

                      <span className="d-md-none"> Save</span>
                      <span className="d-none d-md-block">Save Search</span>
                    </Link>
                  </div>
                  <div className="map d-none d-md-flex">
                    <div className="map-info">
                      <img src={Map} />
                      <i className="google-map"></i>
                    </div>
                    <div className="map-info text">
                      <Link className="map-text d-none d-xl-block" href="#">
                        View on
                      </Link>
                      <Link href="#">Map</Link>
                    </div>
                  </div>
                </div>
                <div className="sale-wrapper">
                  {propertyList.map((property, index) => (
                    <PropertyCard key={index} propertyList={property}  PropertyPrice={property.price.toLocaleString()} />
                  ))}
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PropertyFeatures;
