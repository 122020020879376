import React, { useRef, useState } from "react";
import { Container, Row, Col,Pagination } from "react-bootstrap";

import { Link } from "gatsby";
import "./Pagination.scss";
// import ScrollAnimation from 'react-animate-on-scroll';
const Team = (props) => {
  return (
    <React.Fragment>
      <section className="paginations">
        <Container>
          <Row>
            <Col md={12}>
              <Pagination>
                <Pagination.Item active>{1}</Pagination.Item>

                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item >{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>

                <Pagination.Next />
              </Pagination>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Team;
